import React, { Component } from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import { Helmet } from "react-helmet";
import OtherProductsContainer from '../components/OtherProductsContainer';


class ProductSingle extends Component {
    render() {
        const data = this.props.data.markdownRemark;
        const { siteMetadata } = this.props.data.site;
        const post = this.props.data.markdownRemark;
        const { title, slug, description, keyword, image, featuredImage, newsletter, about } = post.frontmatter;
        return (
            <Layout newsletter={newsletter} about={about}>
                <Helmet>
                    <title>{`${title} | فیلترشکن‌ها`}</title>
                    <link rel="canonical" href={`https://filtershekanha.com/blog/${slug}`} />
                    <meta name="description" content={`${description || siteMetadata.main.description }`} />
                    <meta name="keywords" content={`${keyword || siteMetadata.main.keyword }`} />
                    <meta name="twitter:title" content={`${title}`} />
                    <meta name="twitter:description" content={`${description}`} />
                    <meta name="twitter:image" content={`${image || siteMetadata.main.cover }`} />
                    <meta name="twitter:url" content={`https://filtershekanha.com/blog/${slug}`} />
                    <meta property="og:url" content={`https://filtershekanha.com/blog/${slug}`} />
                    <meta property="og:image" content={`${image || siteMetadata.main.cover }`} />
                    <meta property="og:title" content={`${title}`} />
                    <meta property="og:description" content={`${description || siteMetadata.main.description }`} />
                </Helmet> 
                <div className="row my-5 justify-content-center bg-title-box-product">
                    <div className="col-12 my-md-5 py-md-5"></div>
                    <div className="col-12 my-5">
                        <h1 className="text-center c-a">{title}</h1>
                    </div>
                    <div className="col-md-3 col-6 text-center my-5 py-5">
                        <img src={featuredImage} className="w256 mx-auto" alt={`${title}`} />
                    </div>
                    <div className="col-12"></div>
                    <div className="col-md-8 col-12 text-justify dir-rtl my-5">
                        <div dangerouslySetInnerHTML={{ __html: data.html }} />
                    </div>
                </div>
                <OtherProductsContainer />
            </Layout>
        )
    }
}

export const query = graphql`
    query ProductQuery($slug: String!) {
        site {
            siteMetadata {
                main {
                    title
                    description
                    image
                    cover
                    keyword
                }
            }
        }        
        markdownRemark(fields: { slug: { eq: $slug } }) {
            html
            frontmatter {
                title
                description
                image
                slug
                keyword
                newsletter
                about
                featuredImage
            }
        }
    }
`

export default ProductSingle;